var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineText", {
        attrs: { label: "שם הפרק" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.displayName,
          callback: function($$v) {
            _vm.$set(_vm.model, "displayName", $$v)
          },
          expression: "model.displayName"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "כותרת תווית יחידה" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.unitDisplayNameText,
          callback: function($$v) {
            _vm.$set(_vm.model, "unitDisplayNameText", $$v)
          },
          expression: "model.unitDisplayNameText"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "סדר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.sortOrder,
          callback: function($$v) {
            _vm.$set(_vm.model, "sortOrder", $$v)
          },
          expression: "model.sortOrder"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "תרגול מקושר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lessonExerciseId,
          callback: function($$v) {
            _vm.$set(_vm.model, "lessonExerciseId", $$v)
          },
          expression: "model.lessonExerciseId"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "סוג תרגול",
          filterable: false,
          clearable: false,
          options: [
            { Value: "0", Text: "רגיל" },
            { Value: 1, Text: "חקיקה" },
            { Value: 2, Text: "מיקודית" },
            { Value: 3, Text: "חזרה" }
          ],
          required: "true"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.expoType,
          callback: function($$v) {
            _vm.$set(_vm.model, "expoType", $$v)
          },
          expression: "model.expoType"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "קטגוריה",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getCategories
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.exerciseCategoryId,
          callback: function($$v) {
            _vm.$set(_vm.model, "exerciseCategoryId", $$v)
          },
          expression: "model.exerciseCategoryId"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "קבוצה",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getGroups
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.groupId,
          callback: function($$v) {
            _vm.$set(_vm.model, "groupId", $$v)
          },
          expression: "model.groupId"
        }
      }),
      _c(
        "b-field",
        { attrs: { label: "פתיח" } },
        [
          _c("NewActivityEditor", {
            ref: "editor",
            attrs: {
              ckEditorContent: _vm.model.introText,
              content: _vm.model.introText
            },
            on: { onEditorChange: _vm.onEditorChange }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }