var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "LessonEntityDetails__Component" },
    [
      _c("FieldInlineDateTime", {
        attrs: {
          label: "זמן התחלת ההרצאה",
          "hide-hover": true,
          position: "is-bottom-left"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.startTime,
          callback: function($$v) {
            _vm.$set(_vm.model, "startTime", $$v)
          },
          expression: "model.startTime"
        }
      }),
      _c("FieldInlineDateTime", {
        attrs: {
          label: "זמן סיום ההרצאה",
          "hide-hover": true,
          position: "is-bottom-left"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.endTime,
          callback: function($$v) {
            _vm.$set(_vm.model, "endTime", $$v)
          },
          expression: "model.endTime"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "מרצה",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getTeachers
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lecturesTeachersId,
          callback: function($$v) {
            _vm.$set(_vm.model, "lecturesTeachersId", $$v)
          },
          expression: "model.lecturesTeachersId"
        }
      }),
      _c("FieldInlineSelect", {
        attrs: {
          label: "אולם",
          filterable: true,
          clearable: true,
          optionsAsync: _vm.getHalls
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.lecturesHallsId,
          callback: function($$v) {
            _vm.$set(_vm.model, "lecturesHallsId", $$v)
          },
          expression: "model.lecturesHallsId"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "קיבולת" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.capacity,
          callback: function($$v) {
            _vm.$set(_vm.model, "capacity", $$v)
          },
          expression: "model.capacity"
        }
      }),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "קו צהוב" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.capacityYellowLine,
          callback: function($$v) {
            _vm.$set(_vm.model, "capacityYellowLine", $$v)
          },
          expression: "model.capacityYellowLine"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה לתלמיד" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.userNote,
          callback: function($$v) {
            _vm.$set(_vm.model, "userNote", $$v)
          },
          expression: "model.userNote"
        }
      }),
      _c("FieldInlineText", {
        attrs: { label: "הערה פנימית" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.editorNote,
          callback: function($$v) {
            _vm.$set(_vm.model, "editorNote", $$v)
          },
          expression: "model.editorNote"
        }
      }),
      _c("NewActivityEditor", {
        ref: "editor",
        attrs: {
          ckEditorContent: _vm.model.instractionsNote,
          content: _vm.model.instractionsNote
        },
        on: { onEditorChange: _vm.onEditorChange }
      }),
      _c(
        "b-checkbox",
        {
          staticStyle: { "margin-top": "10px" },
          on: { input: _vm.onChange },
          model: {
            value: _vm.model.isWebinarRegistrationRequired,
            callback: function($$v) {
              _vm.$set(_vm.model, "isWebinarRegistrationRequired", $$v)
            },
            expression: "model.isWebinarRegistrationRequired"
          }
        },
        [_vm._v(" נדרשת הרשמה לוובינר ")]
      ),
      _c("FieldInlineText", {
        attrs: { type: "number", label: "מזהה לוובינר" },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.webinarId,
          callback: function($$v) {
            _vm.$set(_vm.model, "webinarId", $$v)
          },
          expression: "model.webinarId"
        }
      }),
      _c("FieldInlineText", {
        attrs: {
          readOnly: _vm.model.isWebinarRegistrationRequired,
          label: "קישור לוובינר"
        },
        on: { onChange: _vm.onChange },
        model: {
          value: _vm.model.webinarUrl,
          callback: function($$v) {
            _vm.$set(_vm.model, "webinarUrl", $$v)
          },
          expression: "model.webinarUrl"
        }
      }),
      _c(
        "div",
        { staticClass: "checkbox-container" },
        [
          _c(
            "b-switch",
            {
              staticClass: "ltr",
              on: { input: _vm.onChange },
              model: {
                value: _vm.model.status,
                callback: function($$v) {
                  _vm.$set(_vm.model, "status", $$v)
                },
                expression: "model.status"
              }
            },
            [_vm._v(_vm._s(_vm.model.status ? "פעיל" : "לא פעיל"))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }